@use "src/_theme.scss";
@mixin Ugu {
	.ugu-container {

	}
	.block {
		$h: 48px;
		$p: 8px;
		position: relative;
		height: $h + 2*$p;
		margin: 8px;

		$lw: 128px;
		$cw: 256px;
		$bw: 128px;
		width: $lw + $cw + $bw;
		border-radius: 8px;
		overflow: hidden;
		border: 2px solid rgba(theme.$theme,1);
		box-sizing: border-box;
		border-top-left-radius: 0px;

		.block-border {
			position: absolute;
			top: 0;
			left: 0;
			width: $lw + $cw + $bw;
			height: $h + 2*$p;
			border: 4px solid rgba(theme.$theme2,1);
			
			box-sizing: border-box;
		}
		$O: 0.9;
		.block-label {
			position: absolute;
			top: 0;
			left: 0;
			width: $lw;
			height: $h + 2*$p;
			background: rgba(theme.$theme, $O);
			padding: 8px;
			box-sizing: border-box;
			@include theme.altFont;
			span {
				display: block;
				height: $h/2;
				line-height: $h/2;
			}
		}
		
		.block-content {
			position: absolute;
			top: 0;
			left: $lw;
			width: $cw;
			height: $h + 2*$p;
			background: rgba(theme.$theme2, $O);
			padding: 8px;
			box-sizing: border-box;
			text-align: left;
			
			strong {
				display: inline-block;
				position: relative;
				width: $cw/3;
				@include theme.textFont;
				font-weight: 400;
				font-size: $h/3;
				height: $h;
				text-align: right;
				line-height: $h;
				top: 0;
				vertical-align: top;
			}
			

			img {
				height: $h;
				display: inline-block;
				position: relative;
				margin-left: $p;
				
				
			}
			div {
				display: inline-block;
				position: relative;
				width: $cw/3 + $p;
				vertical-align: top;
				text-align: center;
				top: $p/2 + 2px;
				left: $p;
			}
			.pct-deposit {
				display: block;
				height: $h/2;
			}
			.deposit-tag {
				display: block;
				height: $h/2;
				font-size: 12px;
				line-height: 12px;
			}

		}
		
		.block-buttons {
			position: absolute;
			top: 0;
			left: $lw + $cw;
			width: $bw;
			height: $h + 2*$p;
			background: rgba(theme.$themeTetradAnti, $O);
			padding: 8px;
			box-sizing: border-box;
		}
	}
	.ugu-pool {
		position: relative;
		overflow: hidden;
		&__name {
			position: relative;
			text-align: left;
			font-size: 42px;
			@include theme.titleFont;
			img {
				height: 64px;
				vertical-align: middle;
				margin-right: 16px;
			}
			span {
				vertical-align: middle;
			}
			margin-bottom: 32px;
		}
		&__alloc {
			position: absolute;
			top: 0;
			right: 0;
			width: 64px;
			height: 64px;
			background: theme.$theme;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 16px;
			strong {
				display: block;
				@include theme.titleFont;
				font-size: 64px;
				font-weight: 400;
				line-height: 64px;
				position: relative;
				top: -16px;
			}
			span {
				display: block;
				position: relative;
				top: -24px;
			}
		}
		&__amount {

		}
		&__pending {

		}
		&__link {
			margin-top: 64px;
		}
		&__btn {
			margin: 4px;
			font-size: 16px;
		}
		p {
			font-size: 12px;
		}
		border-top: 1px solid theme.$theme2;
		border-bottom: 1px solid white;
		margin: 16px;
		padding: 16px;
	}
	.emissions-bar-container {
		position: relative;
		text-align: center;
		margin: 16px;
		.ugu-pool__rate {
			font-size: 12px;
		}
		.emissions-bar {
			position: relative;
			margin: 0 auto;
			width: 420px;
			height: 12px;
			background: #666666;
			border-radius: 8px;
			.emissions-bar-inner {
				position: relative;
				top: 2px;
				left: 2px;
				background: theme.$theme2;
				height: 8px;
				border-radius: 8px;
				&.length {
					&--0 {
						width: 5%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: -90%),
							scale-color(theme.$theme, $saturation: -90%));
					}
					&--1 {
						width: 20%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: -50%),
							scale-color(theme.$theme, $saturation: -50%));
					}
					&--2 {
						width: 35%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: -25%),
							scale-color(theme.$theme, $saturation: -25%));
					}
					&--3 {
						width: 50%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: 0%),
							scale-color(theme.$theme, $saturation: 0%));
					}
					&--4 {
						width: 65%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: 33%),
							scale-color(theme.$theme, $saturation: 33%));
					}
					&--5 {
						width: 80%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: 66%),
							scale-color(theme.$theme, $saturation: 66%));
					}
					&--6 { 
						width: 99%;
						background: linear-gradient(
							90deg,
							scale-color(theme.$theme2, $saturation: 100%),
							scale-color(theme.$theme, $saturation: 100%));
					}
				}


			}
		}

	}

	.ugu-pool__burner {
		position: absolute;
		right: 0;
		top: 64px - 12px;
		width: calc(100% - 512px - 36px);
		max-width: 220px;
		background: rgba(theme.$themeTetradAnti,0.9);
		border-top-left-radius: 32px;
		.ugu-pool__btn {
			font-size: 12px;
			background: none;
			border: none;
			border-bottom: 1px solid white;
		}
		padding: 8px;
		box-sizing: border-box;
		border-bottom-left-radius: 32px;
		border-bottom-right-radius: 32px;
		border-right: 2px solid theme.$theme;
		border-left: 2px solid theme.$themeTetradAnti;
	}

	.block-buttons {
		button.ugu-pool__btn {
			background: none;
			border: none;
			position: relative;
			left: -4px;
		}
	}
	.ugu-pool__amount {
		.block-buttons {
			button.ugu-pool__btn {
				border: none;
				font-size: 36px;
				margin: 0;
				line-height: 36px;
				padding: 0 8px;
			}
			button.ugu-pool__btn + button.ugu-pool__btn {
				margin-left: 16px;

			}
		}
	}

	.circle {
		position: absolute;
	}
	.circle--title {
		$r: 240px;
		
		top: -$r;
		left: 0;
		width: 2*$r;
		height: 2*$r;
		background: rgba(theme.$theme, 0.4);
		border-radius: $r;
	}
	.circle--title2 {
		$r: 180px;
		
		top: -$r/2;
		left: 45%;
		width: 2*$r;
		height: 2*$r;
		background: rgba(theme.$theme2, 0.7);
		border-radius: $r;
	}

}