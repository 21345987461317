@use "src/_theme.scss";

@mixin buttonSizing ($fontSize) {
  $fS: $fontSize;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/12;
  padding: $pV $pH;
  border-radius: $fS/2;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: min($bW,1px) solid rgba(theme.$borderColor, 0.4);
  font-size: $fS;
  

}

@mixin buttonStyling {
	cursor: pointer;
	outline: none;

    background: none;
    
    color: white;
    
    
     transform: scale(1) translateX(0) translateY(0);;
  transition: 0.2s transform ease-in-out,
  			  0.2s filter ease-in-out, 0.2s padding ease-in-out,
          0.2s margin-bottom ease-in-out,
              0.2s background ease-in-out, 
              0.2s border ease-in-out, 
              0.2s color ease-in-out;

    @include theme.buttonFont;
    text-transform: uppercase;
    &:hover {
    	background: none;
    
      color: theme.$theme3;
    	border-bottom-color: theme.$buttonBorderHOVER;
    	transform: scale(1) translateX(2px) translateY(2px);
    	
     
      
    }
    


}

@mixin buttonShadow {
	
}

@mixin tlbr($indent) {
  top: $indent;
  left: $indent;
  right: $indent;
  bottom: $indent;  
}

@mixin TLBR {
  @include tlbr(0);
}

@mixin f_tlbr($indent) {
  @include tlbr($indent);
  position: fixed;    
}

@mixin F_TLBR {
  @include f_tlbr(0);
}

@mixin Toggly {
  transform: translateY(0);
  transition: 0.2s transform ease-in-out;

  &--false {
    transform: translateY(100vh);
  }
}

@mixin TOGGLY {
  @include F_TLBR;
  transform: translateY(0);
  transition: 0.2s transform ease-in-out;

  &--false {
    transform: translateY(100vh);
  }
}


@mixin scrolly {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    padding: 1px;

  }

  &::-webkit-scrollbar-button {

  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.8);
    border-radius: 8px;
    border:none;

  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(90deg, theme.$theme, theme.$theme2, theme.$themeTetradAnti);
    padding: 1px;

  }
  &::-webkit-scrollbar-track-piece {
    

  }
  &::-webkit-scrollbar-corner {


  }
}
