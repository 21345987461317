@mixin City {
	.city {
		display: none;
		&-shio--shio,
		&-toray--toray,
		&-lauren--lauren,
		&-terrara--terrara,
		&-nibo--nibo,
		&-arbo--arbo {
			display: block;

		}
	}
	.citizens-only {
		&--false {
			display: none;
		}
		button {
			margin: 4px;
		}
	}
}