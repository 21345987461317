@use "src/_theme.scss";
@mixin pENERg {
  $eclr: #a60d7d;
  $pclr: #3a0027;
  $e9: adjust-hue($eclr, 90deg);
  $e270: adjust-hue($eclr, 270deg);
  $p9: adjust-hue($pclr, 90deg);
  $p270: adjust-hue($pclr, 270deg);
  .penerg-pool {
    background: linear-gradient(170deg, black, black, black, black, $e9);
    border-top: 4px solid $e9;
    border-left: 4px solid $p9;
    border-radius: 8px;
    padding: 8px;
    margin: 16px;
    div {
      margin: 8px;
    }
    .reward {
      filter: drop-shadow(0 0 8px $e9);
      font-size: 18px;
      line-height: 24px;
      .tokie{
        height: 36px;
        margin-right: 8px;
      }
    }
    .tokie {
      height: 24px;
      vertical-align: middle;
    }
    span.mini {
      font-size: 12px;
    }
  }
}
