@use "src/_bg.scss";

@use "src/_btn.scss";
@use "src/_theme.scss";
@use "src/_utils.scss";
@use "src/_side.scss";
@use "src/_token.scss";
@use "src/_wallet.scss";
@use "src/_footer.scss";
@use "src/_city.scss";
@use "src/_citizen.scss";
@use "src/_ugu.scss";
@use "src/_penerg.scss";

.App {
  text-align: center;
  padding: 16px;

}
 
.world {
  z-index: 2;

}


body {
  @include theme.textFont;

}
strong, button, input {
  @include theme.altFont;
}


body {
  background: theme.$bg;
  color: theme.$fontColor;
  width: 100%;

  height: 100vh;

  margin: 0;

}





a, a:visited {
  @include theme.gradientText(theme.$theme,theme.$theme1);
  text-decoration: none;
  &:hover {
    @include theme.gradientText(theme.$theme1,theme.$theme);
  }
}







@include btn.Btn;

.navi {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;

  background: rgba(theme.$bg,0.8);
  padding: 8px 16px;
  box-sizing: border-box;

  border: 2px solid rgba(0,0,0,0.4);
  border-top-left-radius: 100vw;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.deli-container {
  border-bottom: 1px solid theme.$pink;
  
  max-width: 800px;
  margin: 0 auto 32px;
  p {
    margin-top: 8px;
    margin-bottom: 16px;
    background: rgba(theme.$bg,0.8);
    padding: 4px 8px;
    box-sizing: border-box;
    border: 2px solid rgba(black,0.4);
    filter: drop-shadow(2px 2px 2px black);
    border-radius: 2px;
  }

}

.connected--false .world {
  display: none;
}


@include token.Token;
@include wallet.Wallet;
@include footer.Footer;
.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  margin: 0;
  font-size: 20px;
}

.focus {
  padding: 32px 64px 64px;
  text-align: left;
  background: rgba(theme.$bg,0.6);
  border: 1px solid rgba(white, 0.4);
  filter: drop-shadow(2px 2px 2px black);
  border-radius: 4px;
  max-width: 1080px;
  margin: 256px 32px  16px;
  a { font-size: 24px;}
  a, p, h2 {filter: drop-shadow(2px 2px 2px black);}
  h2 {
    @include theme.gradBP();
    font-size: 32px;
  }
}

h1, h2, h3 {
  @include theme.titleFont;

}

h2 {
  @include theme.gradientText(theme.$theme,theme.$theme1);
  font-size: 64px;
  display: inline-block;
}

h3 {
  font-size: 48px;
  font-weight: 400;
}

.item-view {
  &--false {
    display:none;
  }
}

.item {
  height: 480px;
  width: 480px;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid rgba(255,255,255,0.4);
  border-radius: 8px;
  margin: 16px auto;
  &--standard {
    background: #233248;
  }
  &--master {
    background: #554615;
  }
  &--god {
    background: #a15f1e;
  }
}
.sbtn {
  &--false {
    display: none;
  }
}

.pending {
  $penderH: 48px;
    position: fixed;
    z-index: 14;
    top:0;
    left: 0;
    height: $penderH;
    line-height: $penderH;
    width: 100%;
    background: white;
    color: black;
    text-align: center;
    font-size: $penderH*2/3;
    @include theme.titleFont;
    font-weight: 500;
    &--false {
      display: none;
    }
  }

  .inventory {
    cursor: pointer;
    &--false {
      .inventory-group {
        display: none;
      }
    }
    .inventory-group {

      max-width: 640px;
      margin: 0 auto;
      &__balance {
        
      }
      .inventory-item {
        max-width: 640px;
        padding: 16px;
        box-sizing: border-box;
        border: 1px solid rgba(255,255,255,0.4);
        border-radius: 8px;
        margin-top: 16px;
      }
      .close-btn {
        margin: 12px auto;
      }
    }
    .inventory-nav {
      margin-top: 16px;
      span {
        font-size: 24px;
        margin: 0 16px;
      }
    }

  }

  .store {
    h2 {
      display: block;
    }    
      button {
        margin: 8px 8px; 
        img {
          height: 32px;
          vertical-align: middle;
          margin-right: 8px;
        }
        span {
          vertical-align: middle;
        }
      }
    .item {
      &__name {
        font-size: 20px;
        padding: 8px;
        border-bottom: 1px solid white;
        margin: 8px auto;
      }
      &__cost {
        font-size: 32px;
        margin: 8px auto;
      }
      &__multiplier {
        margin: 8px auto;
      }
    }
  }


  .shortcuts {
    position: fixed;
    left: 16px;
    bottom: 32px;
    width: 136px;
    z-index: 999;
    button {
      width: 136px;
      margin: 4px 0;
      font-size: 16px;
      background: rgba(0,0,0,0.4);
    }
  }

  .inventory-container, 
  .wallet-container, 
  .store-container, 
  .travel-container, 
  .bank,
  .map-container {
    &--false {
      display: none;
    }
  }

  .travel-container, .store-container, .inventory-container, .map-container {
    position: fixed;
    top: 64px;
    left: 0;
    right: 16px;
    bottom: 0;
    border-top: 4px solid white;
    border-bottom: 1px solid white;
    border-radius: 0;
    padding: 16px;
    background: linear-gradient(96deg,theme.$themeTetrad, black,black,black,black,black,theme.$theme);
    overflow-y: scroll;
    h2 {
      
    }
    @include utils.scrolly;

  }
  .map-container {
    overflow-x: scroll;

  }

.salt-mines {
  display: none;
  &--salt {
    display: block;
  }
  .MineSalt--disabled {
    display: none;
  }
  button {
    img {
      height: 48px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
  }
}
.ugu-container {
  display: none;
  &--ugu {
    display: block;
  }
}

.lake-lauren {
  display: none;
  &--lake {
    display: block;
  }
}

.wallet-container {
  z-index: 999;
}
.store-container {
  z-index: 998;
}
.inventory-container {
  z-index: 997;
}
.travel-container {
  z-index: 996;
  a {
    display: block;
  }
}
.map-container {
  z-index: 999;
}

.map-container {
  .the-map {
    position: relative;
    &__title {
      position: fixed;
      top: 32px;
      left: 32px;
      margin: 0;
      background: rgba(0,0,0,0.4);
      padding: 16px 16px 8px;
      border-radius: 16px;
    }
  }
}

.side-el {
  
  margin: 0 128px;

  background: theme.$theme;
  padding: 0 0px 8px;
  box-sizing: border-box;
  z-index: 99;
  border: 1px solid white;
  
  border-radius: 8px;

  .side-btn {
    
    div {
      margin-top: 16px;
    }
    .Display {
      font-size: 24px;

    }
  }
  .side-content {
    background: theme.$bgGradientColorOne;
  }
  &--false {
    .side-content {
      display: none;
    }
    
  }
  
  .lake__stats {
    ul {
      list-style-type: none;
      text-align: left;
      margin: 0;
      padding: 0; 
      li {
        margin: 4px 8px;
        border-bottom: 1px solid white;
      }
    }
  }
}
span.Display-larger {
      margin: 0 8px;
      font-size: 24px;
    }
    button[disabled] {
      background: black;
      &:hover {
        filter: drop-shadow(2px 2px 2px black);
        color: white;
        transform: scale(1) translateX(0) translateY(0);
        border-color: rgba(255,255,255,0.4);
      }
    }

    .weather {
      margin-top: 24px;
      font-size: 24px;
    }
  .checkCollected {
    margin-bottom: 16px;
    margin-top: 16px;

  }
  .collecting {
    button {
      margin: 16px;
    }
  }

  .travel-buttons {
    button {
      margin: 8px auto;
      display: block;
    }

  }

// BANK ---------------------------------------------------------------
// BANK ---------------------------------------------------------------
// BANK ---------------------------------------------------------------
// BANK ---------------------------------------------------------------
  .bank {
    display: none;
    &--bank {
      display: block;
    }


  .App-converter {
  max-width: 640px;
  background: rgba(theme.$theme, 0.4); 
  border: 2px solid rgba(255,255,255,0.4);
  border-radius: 16px;
  filter: drop-shadow(0 0 4px rgba(theme.$theme2,0.6));
  padding: 16px;
  margin: 8px auto;
  &__title {
    font-size: 24px;
    color: white;
    margin-bottom: 32px;
    @include theme.altFont;
  }
  p {
    color: #aeaeae;
    margin: 8px 0;
  }
}


}
.App-userBalances {
  div {
    height: 64px;
    display: inline-block;
    line-height: 64px;
    margin: 16px;

    .token-icon {
      height: 64px;
      vertical-align: middle;
    }
    span {
      font-size: 24px;
      color: white;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
}
.App-Folio {
  div.Portfolio-Title {
    display:  block;
    color: white;
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    margin: 8px;
  }
  div {
    height: 48px;
    display: inline-block;
    line-height: 48px;
    margin: 16px;

    .token-icon {
      height: 48px;
      vertical-align: middle;
    }
    span {
      font-size: 18px;
      color: white;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
}



.energy-grid {
  display: none;
  &--grid {
    display: block;
  }
}

.refinery {
  display: none;
  &--refine {
    display: block;
  }
  .App {
  min-height: 100vh;
  margin: 0;
  &--true {
    .App-preconnect {
      display: none;
    }
    .App-core {
      display: block;
      position: relative;

    }
  }
  input {
    outline: none;
    border: 1px solid rgba(theme.$borderColor, 0.5);
    background: rgba(theme.$theme2, 0.2);
    color: white;
    font-size: 24px;
    margin-right: 16px;
    padding: 8px; 
  }
}
.current-process {
  position: fixed;
  left: 0;
  top: 64px;

  padding: 16px;
  background: rgba(theme.$theme, 0.8);
  z-index: 500;
  h3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  border-bottom-right-radius: 32px;
  border-top: 4px solid theme.$theme1;
}
}
.ingredient, .material {
  cursor: pointer;
  padding: 8px;
  box-sizing: border-box;
  background: theme.$theme2;
  margin: 4px;
  transition: 0.1s background ease-in-out;
  &:hover {
    background: theme.$theme3;
  }
  img {
    height: 24px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

.step--false {
  display: none;
}

// ---------------------------------------------------------------------------------------
// Input Overlay --------------------------------------------------------------------------

$inputOverlayButtonSize: 20px;
$inputOverlayButtonHeight: 7*$inputOverlayButtonSize/4;
$inputOverlayGutter: 16px;
.btn {
  
  & + .btn {
    margin-left: 8px;
  }
}


.theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w:1080px;

  z-index: 12;
  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.9);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: min($like8px/4,2px) solid rgba(theme.$borderColor, 0.5);
    background: rgba(theme.$theme2, 0.2);
    color: theme.$borderColor;
    padding: 0 $like8px;
    width: 100%;
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: absolute;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} + 36px);
    right: calc(#{$inputOverlayGutter} + 16px);
  }
  .btn--back {
    position: absolute;
    top: 256px;
    left: 16px;
    width: calc(100vw - 52px);
  }

  .btn--approve{
    position: absolute;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter} + 16px);
    width: calc(100vw - 52px);
   
    margin: 0;
    box-sizing:border-box;
  }

  .btn--deposit, .btn--withdraw {
    position: absolute;
    top: calc(50vh + 24px + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    width: calc(100vw - 52px);
    margin: 0;
    box-sizing:border-box;

  }

  &__balance, &__poolbalance  {
    position: absolute;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    width: 100%;
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: absolute;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    width: 100%;
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;
  }


 &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
    .theInput__poolbalance {
      display: block;
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  &.INTENT__theInput--remove {
    .btn--deposit {
      display: none;
    }
    .btn--approve, .btn--withdraw {
      display: block;
    }
    .theInput__poolbalance {
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
      display: block;
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  .input-inner {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
  }
}



.farms-container {
display: none;
  &--farms {
    display: block;
  }
  .App {
  text-align: left;
  padding: 16px;

}
p {
  margin-top: 96px;
}
.world {
  z-index: 2;

}

strong, button, input {
  @include theme.altFont;
}







a, a:visited {
  text-decoration: none;
  &:hover {
    color: theme.$theme2;
  }
}







@include btn.Btn;
button {
  font-size: 14px;
}
.navi {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;

  background: rgba(theme.$bg,0.8);
  padding: 8px 16px;
  box-sizing: border-box;

  border: 2px solid rgba(0,0,0,0.4);
  border-top-left-radius: 100vw;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.deli-container {
  border-bottom: 1px solid theme.$pink;
  
  max-width: 800px;
  margin: 0 auto 32px;
  p {
    margin-top: 8px;
    margin-bottom: 16px;
    background: rgba(theme.$bg,0.8);
    padding: 4px 8px;
    box-sizing: border-box;
    border: 2px solid rgba(black,0.4);
    filter: drop-shadow(2px 2px 2px black);
    border-radius: 2px;
  }

}

.connected--false .world {
  display: none;
}


@include token.Token;
@include wallet.Wallet;
@include footer.Footer;
.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  margin: 0;
  font-size: 20px;
}

.focus {
  padding: 32px 64px 64px;
  text-align: left;
  background: rgba(theme.$bg,0.6);
  border: 1px solid rgba(white, 0.4);
  filter: drop-shadow(2px 2px 2px black);
  border-radius: 4px;
  max-width: 1080px;
  margin: 256px 32px  16px;
  a { font-size: 24px;}
  a, p, h2 {filter: drop-shadow(2px 2px 2px black);}
  h2 {
    color: theme.$theme;
    font-size: 32px;
  }
}

h1, h2, h3 {
  @include theme.titleFont;

}

h2 {
   color: theme.$theme1;
  font-size: 24px;
  display: inline-block;
  line-height: 64px;
  margin: 0;
  text-align: left;
  filter: drop-shadow(2px 2px 2px black);
}


.farm {
  max-width: 640px;
  max-height: 640px;
  height: max(40vh,350px);
  width: max(40vh,350px);
  margin: 16px;
  position: relative;
  display: inline-block;
  border: 4px solid white;
  border-radius: 4px;
  filter: drop-shadow(2px 2px 2px black);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  div {
    background: rgba(0,0,0,0.4);
    padding: 16px;
    position: absolute;
    bottom: 0;
    width: 60%;
    left: 0;
    text-align: left;
    border-top-right-radius: 24px;
    button {
      margin: 8px;
    }
  }
}


.farms--false {
  display: none;
}

.farmnav {
  button.btn {
    margin: 8px
  }
  span {
    padding: 2px;
    font-size: 24px;
    background: theme.$theme;
  }

}

.control-btns {
  position: fixed;
  top: 50px;
  button {
    margin: 2px 4px;
    background: rgba(0,0,0,0.4);
  }
}

.balances {
  text-align: right;
  filter: drop-shadow(2px 2px 2px black);
  span {
    margin: 0 8px;
    display: inline-block;
  }
  margin: 2px auto 16px;
  background: theme.$theme;
  position: fixed;
  bottom: 64px;
  left: -4px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 4px solid theme.$theme2;
  transition: 0.2s transform ease-in-out;
  &.balances--emissions {
    bottom: 104px;
    
    $w: 700px;
    $x1: $w - 94px;

    &.balances--false {
      transform: translateX(calc(100px - 100%));
    }
  }
  &.balances--burns {
    bottom: 144px;
    
    $w: 700px;
    $x1: $w - 94px;

    &.balances--false {
      transform: translateX(calc(110px - 100%));
    }
  }
  &.balances--true {
    transform: translateX(0);
  }
  $w: 640px;
  $x1: $w - 60px;
 
  &.balances--false {
    transform: translateX(calc(66px - 100%));
  }
 

}

span.click-span {
  cursor: pointer;
  font-weight: 600;
  color: theme.$theme1;
  &:hover{
    filter:drop-shadow(2px 2px 2px black);
  }
}

.pender {
  display: none;
  &.isPending--true {
    display: block;
  }
  padding-left: 16px;
  padding-top: 32px;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: calc(100vh - 128px - 32px);
  background: theme.$theme;
  color: white;
  line-height: 64px;
  font-size: 20px;
}

.toggle-minters {
  position: fixed;
  left: 144px;
  top: 52px;
  background: rgba(0,0,0,0.4);
  &:hover {
    background: rgba(0,0,0,0.4);
  }
}
.approve-water {
  position: fixed;
  left: 350px;
  top: 52px;
  background: rgba(0,0,0,0.4);
  &:hover {
    background: rgba(0,0,0,0.4);
  }
}
.refresh-farms {
  position: fixed;
  left: 530px;
  top: 52px;
  background: rgba(0,0,0,0.4);
  &:hover {
    background: rgba(0,0,0,0.4);
  }
}


.mint-btns {
  display: block;
  transition: 0.2s transform ease-in-out;
  &.mint-btns--false {
    transform: translateX(100%);
  }
  position: fixed;
  right: 0;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  top: 100px;
  width: 226px;
  text-align: right;

  transform: translateX(2px);
  background: rgba(0,0,0,0.8);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 2px solid white;
  button {
    margin: 4px 0;
  }
}

.global-emissions {
  margin: 4px 0;
  span {
    margin: 0px 8px;
  }
}

.farms {
  margin-bottom: 128px;
  margin-left: 64px;
  margin-right: 64px;
  margin-top: 66px;
  border-left: 4px solid white;
}


  .bg {
    filter: brightness(100%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    img {

      object-fit: cover;
      width: 100%;
      height: 100%;

    }
  }


.header {
  background: #a09d76;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding-left: 16px;
  filter:drop-shadow(2px 2px 2px black);
}

@media (max-width: 800px) and (orientation: portrait) {
  .App {
    padding-left: 8px;
    padding-right: 8px;
  }
  .farms {
    margin-left: 8px;
    margin-right: 8px;
    border-left: none;
    margin-bottom: 256px;
  }
  .farm {
    margin: 4px 0;
    width: calc(100vw - 16px - 8px - 16px);
    height: calc(100vw - 16px - 8px - 16px);
  }
  .mmbtn {
    top: calc(100vh - 48px);
  }
  .footer.isOn--false button{
    top: -80px;
  }
  h2 {
    font-size: 16px;
  }
  .balances {
    width: 100vw;
    height: 48px;
  }
  .balances.balances--emissions {
    bottom: 128px;

  }
}
}
.shortcuts, .toggle-shortcuts {
  transition: 0.2s transform ease-out;
}

.connected--false {
  .shortcuts {
    transform: translateX(-152px);
  }
  .mint-citizen {
    display: none;
  }
  

}

.welcome-citizen {
    display: none;
  }

@include city.City;
@include citizen.Citizen;

.shortcuts-on--false {
  transform: translateX(-152px);
  .toggle-shortcuts {
    transform: translateX(152px);
  }
}
.shortcuts-on--true {
  .toggle-shortcuts {
    
  }
}
.mint-citizen--true {
  display: none;
}

.welcome-citizen--true.welcome-citizen-- {
  display: block;
}

@include ugu.Ugu;
@include penerg.pENERg;