@use "src/_theme.scss";

@mixin Footer {
	.footer {
		position: fixed;

		background: rgba(0,0,0,0.8);
		padding: 8px;
		
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		transform: translateX(0) translateY(0);
		transition: 0.2s transform ease-in-out;
		&.isOn--false {
			transform: translateX(0) translateY(100%);
			button {top: -64px;}
		}
		button {position: absolute; top: -32px; right: 16px; font-size: 14px; transition: 0.2s top ease-in-out;}
		a {
			text-decoration: none;
			padding: 0 8px;
			color: theme.$pink;
			display: inline-block;
		}
		div {
			max-width: 100%;
		}
	}
}